*, ::after, ::before {
    box-sizing: border-box;
}

img {
    vertical-align: middle;
    border-style: none;
}

nav.navbar {
    padding: 0px;
    background-color: #343a40!important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #fff;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    height: 32px;

    .container-fluid {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-left: 0px;
        width: 100%;
        padding-left: 0px;
        padding-right: 15px;

        .navbar-brand {
            margin-right: 1rem;
            padding: 0px;
            font-size: 15px;
            font-family: "GE Inspira Sans",sans-serif;
            display: inline-block;
            white-space: nowrap;
            font-weight: 400;
            line-height: 1.5;

            .logo {
                margin-right: 10px;
                vertical-align: middle;
                border-style: none;
            }
        }

        .navbar-nav {
            margin-left: auto!important;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            text-align: left;

            .nav-item {
                color: rgb(136,154,165);
                font-size: 0.8rem;
                padding-left: 12px;
            }
        }

        .logo-top-right {
            margin-left: 5px;
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 0px;
        }
    }
}